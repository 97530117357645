// const BASE_URL = 'https://api.rishitechno.com/api' 
// const FILE_URL = 'https://api.rishitechno.com' 
// const BASE_URL = 'https://api.rishitechno.com/api' 
// const FILE_URL = 'https://api.rishitechno.com' 

// const BASE_URL = 'http://127.0.0.1:8000/api'
// const BASE_URL = process.env.REACT_APP_API_ENDPOINT
// const FILE_URL = 'http://127.0.0.1:8000'

const BASE_URL = 'https://api.fitsair.online/api' 
const FILE_URL = 'https://api.fitsair.online'

export const API_ENDPOINTS = {  
  LOGIN: `${BASE_URL}/login`,
  REFRESH_TOKEN: `${BASE_URL}/refresh`,
  LOGOUT: `${BASE_URL}/logout`,

  UPLOAD_ROSTER: `${BASE_URL}/uploadexcelroster`,
  UPLOAD_ROSTER_DECK: `${BASE_URL}/uploadexcelroster-deck`,
  UPLOAD_ROSTER_CREWPR: `${BASE_URL}/uploadexcelroster-crew-pr`,
  UPLOAD_ROSTER_DECKPR: `${BASE_URL}/uploadexcelroster-deck-pr`,
  GET_USER_DATA: `${BASE_URL}/user`,
  UPDATE_AUDIT_TRAIL: `${BASE_URL}/update-audit-trail`,
  UPDATE_AIRCRAFT_DATA: `${BASE_URL}/updateaircraftdata`,
  UPDATE_AIRPORT_DATA: `${BASE_URL}/updateairportdata`,
  UPDATE_AIRCRAFT_MODEL: `${BASE_URL}/updateaircraftmodel`,
  UPDATE_CREW_CATEGORY: `${BASE_URL}/updatecrewcategory`,
  DELETE_CREW_CATEGORY: `${BASE_URL}/delete-crewcategory`,
  GET_CREW_CATEGORY_SINGLE: `${BASE_URL}/get-crewcategory-single`,
  UPDATE_CREW_MEMBER: `${BASE_URL}/updatecrewmember`,
  UPDATE_SCHEDULE_PERIOD: `${BASE_URL}/updatescheduleperiods`,
  UPDATE_SCHEDULE: `${BASE_URL}/updateschedule`,
  EDIT_SCHEDULE_LEG: `${BASE_URL}/editScheduleLeg`,
  GET_AIRCRAFT_DATA: `${BASE_URL}/getaircraftdata`,
  GET_AIRPORTS: `${BASE_URL}/getairports`,
  GET_AIRPORT: `${BASE_URL}/getairport`,
  GET_IATACODES: `${BASE_URL}/getiatacodes`,
  GET_AIRCRAFT_MODELS: `${BASE_URL}/getaircraftmodels`,
  GET_AIRCRAFT_MODEL_SINGLE: `${BASE_URL}/get-aircraftmodel-single`,
  GET_CREW_CATEGORIES: `${BASE_URL}/getcrewcategories`,
  GET_CREW_CATEGORIES_BYTPE: `${BASE_URL}/getcrewcategoriesbytype`,
  GET_NEXT_CREW_ID: `${BASE_URL}/getnextcrewid`,
  GET_PERMISSION_DATA: `${BASE_URL}/get-permission-data`,
  GET_SCHEDULE_PERIODS: `${BASE_URL}/getschedulepeiodssel`,
  GET_SCHEDULE_PERIODS_DATA: `${BASE_URL}/getschedulepeiods`,
  DELETE_AIRPORT_DATA: `${BASE_URL}/deleteairportdata`,
  DELETE_AIRCRAFT_DATA: `${BASE_URL}/deleteaircraftmodel`,
  DELETE_AIRCRAFT_ENTRY: `${BASE_URL}/delete-aircraft-entry`,
  GET_AIRCRAFT_ENTRY_SINGLE: `${BASE_URL}/get-aircraft-entry-single`,
  DELETE_SCHEDULE_PERIOD: `${BASE_URL}/deletescheduleperiod`,
  GET_SAVED_SCHEDULES: `${BASE_URL}/get-saved-schedules`,
  GET_AC_TYPE: `${BASE_URL}/get-ac-types`,
  GET_AC_CONFIG: `${BASE_URL}/get-ac-config`,
  GET_FLIGHT_NUMBERS: `${BASE_URL}/get-flight-numbers`,
  UPDATE_SCHEDULE_SPLIT: `${BASE_URL}/update-schedule-split`,
  GET_SCHEDULE_PERIODS_FILTERED_DATA: `${BASE_URL}/getschedulepeiods_filtered`,
  UPDATE_SINGLE_FLIGHT_SCHEDULE: `${BASE_URL}/update-schedule-single`,
  GET_FLIGHT_CHART_DATA: `${BASE_URL}/get-flight-chart-data`,
  GET_CREW_TYPE_CATEGORY: `${BASE_URL}/get-crew-type-category`,
  UPDATE_CHECKS: `${BASE_URL}/update-checks`,
  GET_CHECKS: `${BASE_URL}/get-checks`,
  DELETE_CHECK: `${BASE_URL}/delete-checks`,
  GET_SINGLE_CHECK: `${BASE_URL}/get-single-check`,
  UPDATE_TRAINING: `${BASE_URL}/update-training`,
  GET_TRAINING_DATA: `${BASE_URL}/get-training-data`,
  DELETE_TRAINING: `${BASE_URL}/delete-training`,
  GET_SINGLE_TRAINING: `${BASE_URL}/get-single-training`,
  UPDATE_MAINTAIN_TYPE: `${BASE_URL}/update-maintain-type`,
  DELETE_MAINTAIN_ENTRY: `${BASE_URL}/delete-maintain-entry`,
  GET_MAINTAIN_TYPE_DATA: `${BASE_URL}/get-maintain-type-data`,
  GET_SINGLE_MAINTAIN_TYPE: `${BASE_URL}/get-maintain-type`,
  DELETE_MAINTAIN_TYPE: `${BASE_URL}/delete-maintain-type`,
  UPDATE_SCHEDULE_PUBLISH: `${BASE_URL}/update-schedule-publish`,
  DELETE_SCHEDULE_ENTRY: `${BASE_URL}/delete-schedule-entry`,
  GET_FLIGHTS_DATA: `${BASE_URL}/get-flights-data`,
  GET_FLIGHTS_MAINTAIN_CHART_DATA: `${BASE_URL}/get-flights-maintain-chart-data`, 
  GET_MAINTAIN_CHART_FILTERED_DATA: `${BASE_URL}/get-maintain-chart-filtered-data`, 
  UPDATE_MAINTAINANCE_ENTRY: `${BASE_URL}/update-maintain-entry`,
  EDIT_MAINTAINANCE_ENTRY: `${BASE_URL}/edit-maintain-entry`, 
  GET_FLIGHT_REGS: `${BASE_URL}/get-flight-regs`,
  UPDATE_FLIGHT_REG_INFO: `${BASE_URL}/update-flight-reg-info`,
  GET_FLIGHT_CHART_DATA_MOVEMENT: `${BASE_URL}/get-flight-chart-data-movement`,
  GET_MASTER_DATA_MAINTAINADD: `${BASE_URL}/get-master-add-maintain`,
  SEARCH_FLIGHTS_CREW_ALLOC: `${BASE_URL}/search-flights-crew-alloc`,
  GET_FLIGHTS_FOR_PARING: `${BASE_URL}/get-flights-for-paring`,
  UPDATE_PARING: `${BASE_URL}/update-paring`,
  UPDATE_PARING_EDIT: `${BASE_URL}/update-paring-edit`,
  GET_PARING: `${BASE_URL}/get-parings`,
  DELETE_ONE_PARING_DETAILS: `${BASE_URL}/delete-one-paring`,
  ADD_NEW_FLIGHT_TO_PAIRING: `${BASE_URL}/add-new-flight-to-pairing`,
  GET_PARING_LIST: `${BASE_URL}/get-parings-list`,
  GET_CREW_DATA: `${BASE_URL}/get-crew-data`,
  UPDATE_PARING_ASSIGNED: `${BASE_URL}/update-paring-assigned`,
  UPDATE_TRAINING_ASSIGNED: `${BASE_URL}/update-training-assigned`,
  UPDATE_ROSTER_ASSIGNED: `${BASE_URL}/update-paring-assigned`,
  UPDATE_PARING_ASSIGNED_CREWING: `${BASE_URL}/update-paring-assigned-crewing`,
  GET_USERS_DATA: `${BASE_URL}/get-users-data`,
  RPT_AIRCRAFT_ROTATION: `${BASE_URL}/aircraft-rotation-report`,
  RPT_OTP: `${BASE_URL}/otp-report`,
  RPT_OTP_DAILY: `${BASE_URL}/otp-report-daily`,
  SET_MEM_OFFDAY: `${BASE_URL}/set-mem-offday`,
  RPT_DELAY_DETAIL: `${BASE_URL}/delay-detail-report`,
  RPT_AC_MAINTENANCE: `${BASE_URL}/ac-maintenance-report`,
  RPT_UNCOVERED_PAIRINGS: `${BASE_URL}/uncovered-pairings-report`,
  RPT_FUEL_BURN: `${BASE_URL}/fuel-burn-report`,
  RPT_FLIGHT_REPORT: `${BASE_URL}/flight-report`,
  RPT_FLIGHT_DETAILS_REPORT: `${BASE_URL}/flight-details-report`,
  SEARCH_PARING_DETAILS: `${BASE_URL}/search-pairings-details`,
  DELETE_USER_ENTRY: `${BASE_URL}/delete-user-entry`,
  GET_ROLES_DATA: `${BASE_URL}/get-roles-data`,
  DELETE_ROLE: `${BASE_URL}/delete-role`,
  UPDATE_ROLE: `${BASE_URL}/update-role`,
  UPDATE_USER: `${BASE_URL}/update-user`,
  UPDATE_PRIVILEDGE: `${BASE_URL}/update-previledge`,
  GET_PRIVILEDGES: `${BASE_URL}/get-previledges`,
  DELETE_PRIVILEDGE: `${BASE_URL}/delete-previledge`,
  GET_SELECTED_FLIGHT_INFO: `${BASE_URL}/load-selected-flight-info`,
  GET_DELAYCODES: `${BASE_URL}/get-delaycodes`,
  SAVE_FLIGHTEDIT_DATA: `${BASE_URL}/update-flightedit-data`,
  GET_CREW_DATA_ROSTER_SINGLE: `${BASE_URL}/load-crew-roster-single`,
  GET_CREW_EVENTS: `${BASE_URL}/get-crew-roster-events`,
  GET_CREW_SEARCH: `${BASE_URL}/get-crew-search`,
  DELETE_CREW: `${BASE_URL}/delete-crew`,
  GET_CREW_FOREDIT: `${BASE_URL}/get-crewfor-edit`,
  GET_CREW_DATA_SR_NM_ID: `${BASE_URL}/get-crewfor-search-id-and-name`,
  GET_FLIGHT_PARINGS_LIST: `${BASE_URL}/get-flight-paring-list`,
  GET_FLIGHT_CREW_DATA: `${BASE_URL}/get-flight-crew-data`,
  GET_CREWING_CHART_DATA: `${BASE_URL}/get-crewing-flight-data`, 
  GET_EVENT_DATA: `${BASE_URL}/get-event-data`, 

  GET_CREWING_CHART_DATA_BYTYPE: `${BASE_URL}/get-crewing-flight-data-bytype`,
  RPT_DUTY_SHEET_DATA: `${BASE_URL}/rpt-duty-sheet-data`,
  RPT_CREW_TRAINING_DATA: `${BASE_URL}/rpt-crew-training-data`,
  RPT_DOC_EXPIRY_DATA: `${BASE_URL}/rpt-doc-expiry-data`,
  GET_PARING_LIST_GD: `${BASE_URL}/get-paring-list-gd`,
  RPT_GD_DATA: `${BASE_URL}/rpt-gd-data`,
  RPT_GD_DATA_CREW: `${BASE_URL}/rpt-gd-data-crew`,
  RPT_GD_ADD_CREW: `${BASE_URL}/rpt-gd-data-add-crew`,
  RPT_API: `${BASE_URL}/api-report`,


  GET_FLY_HOURS: `${BASE_URL}/get-fly-hours`,
  RPT_CREW_CHECKS_DATA: `${BASE_URL}/rpt-crew-checks-data`,
  RPT_AIR_ROTATION_DATA: `${BASE_URL}/rpt-air-rotation-data`,
  RPT_AIR_UTILIZATION_DATA: `${BASE_URL}/rpt-air-utilization-data`,
  RPT_FUEL_BURN_DATA: `${BASE_URL}/rpt-fuel-burn-data`,
  EXPORT_RPT_FUEL_BURN_DATA: `${BASE_URL}/export-rpt-fuel-burn-data`,
  UPDATE_CANCEL_FLIGHTS_ENTRY: `${BASE_URL}/update-cancel-flight-entry`,
  UPDATE_CANCEL_FLIGHT_MOVE: `${BASE_URL}/update-cancel-flight-entry-move`,
  UPDATE_FLIGHT_ROTATION: `${BASE_URL}/update-flight-rotation`,
  SAVE_ATTACHMENTS: `${BASE_URL}/save-attchaments`,
  GET_ATTACHMENTS: `${BASE_URL}/get-attchaments`,
  FILE_STORAGE: `${FILE_URL}/storage/`,
  RPT_IND_CREW_ROSTER_DATA: `${BASE_URL}/rpt-ind-crew-roster-data`,
  UPDATE_PRIVILEGE: `${BASE_URL}/update-privilege`,
  DELETE_PRIVILEGE: `${BASE_URL}/delete-privilege`,
  GET_PRIVILEGES: `${BASE_URL}/get-privileges`,
  UPDATE_PAIRNG_RAPID: `${BASE_URL}/update-pairing-rapid`,
  GET_FLIGHT_DATA_EDIT: `${BASE_URL}/get-flight-data-edit`,
  RPT_DELAY: `${BASE_URL}/rpt-delay`,
  UPDATE_PAIRNG_ONE_ENTRY: `${BASE_URL}/update-pairing-one`,
  SEND_RPT_EMAIL_ACUTILIZE: `${BASE_URL}/send-email-acutilize`,
  GET_USER_PROFILE: `${BASE_URL}/get-user-profile`,
  SEND_RPT_EMAIL_AC_ROTATE: `${BASE_URL}/send-email-ac-rotate`,
  SEND_OTP_EMAIL_PASSWORD: `${BASE_URL}/send-email-password-otp`,
  UPDATE_USER_PASS: `${BASE_URL}/update-user-password`,
  GET_PARING_EDIT: `${BASE_URL}/get-edit-pairing`,
  RPT_AUDIT_TRAIL: `${BASE_URL}/rpt-audit-trail`, 
  GET_PUBLISHED_ROOSTERS_AND_USER_DATA: `${BASE_URL}/published-rosters-and-crew-system-user-data`, 
  PUBLISHED_ROSTER_AUDIT_TRAIL: `${BASE_URL}/published-rosters-audit-trail`, 

  GET_ROSTER_PERIODS: `${BASE_URL}/get-roster-periods`,
  UPDATE_ROSTER_PERIODS: `${BASE_URL}/update-roster-periods`,
  DELETE_PAIRING: `${BASE_URL}/delete-pairing`,
  UPDATE_ROSTER_SWAP: `${BASE_URL}/update-roster-swap`,
  UPDATE_ROSTER_DELETECONTEXT: `${BASE_URL}/update-roster-delete-context`,
  UPDATE_DELAY_CODE: `${BASE_URL}/update-delay-code`,
  RPT_CREW_LIST: `${BASE_URL}/rpt-crew-list`,
  RPT_CREW_DUTY_HOUR: `${BASE_URL}/rpt-crew-duty-hour`,
  RPT_VR_DATA: `${BASE_URL}/rpt-vr-data`,
  UPDATE_CREW_CREDENTIALS: `${BASE_URL}/update-crew-credentials`,
  UPDATE_STANDBY_TIMESLOTS: `${BASE_URL}/update-standby-timeslots`,
  GET_STANDBY_SLOT_DATA: `${BASE_URL}/get-standby-timeslots`,
  DELETE_STANDBY_SLOT: `${BASE_URL}/delete-standby-timeslots`,
  DELETE_DELAYCODE: `${BASE_URL}/delete-delaycode`,
  GET_FLIGHT_NUMBERS_BYDATE: `${BASE_URL}/get-flight-numbers-bydate`,
  RPT_CREW_BLOCK_HOUR: `${BASE_URL}/rpt-crew-block-hour`,
  RPT_CREW_PAYMENTS: `${BASE_URL}/rpt-crew-payment-data`,
  RPT_GET_FILTER_DATA: `${BASE_URL}/rpt-get-filter-data`,
  WRITE_IMAGE_FILE: `${BASE_URL}/write-chartimage-file`,
  GET_AIRPORT_BYFLIGHT: `${BASE_URL}/get-airports-by-flight`,
  UPDATE_FLIGHT_DIVERTION: `${BASE_URL}/update-flight-divertion`,
  UPDATE_FLIGHT_RESCHEDULE: `${BASE_URL}/update-flight-reschedule`,
  UPDATE_FLIGHT_DBDETAILS: `${BASE_URL}/update-flight-resset`,
  GET_FLIGHT_DATA_GDCREW: `${BASE_URL}/get-flight-data-gdcrew`,
  UPDATE_FLIGHT_DATA_GDCREW: `${BASE_URL}/update-flight-data-gdcrew`,
  GET_AIRPORT_AND_AIRCRAFT_DATA: `${BASE_URL}/get-AirportsAndAircraftsData`,
  GET_AIRPORT_BYFLIGHT2: `${BASE_URL}/get-airports-by-flight2`,
  GET_PUBLISHED_ROSTER_DATA: `${BASE_URL}/get-published-roster-data`,
  PUBLISH_ROSTER: `${BASE_URL}/publish-roster`,
  RPT_SNY_DETAILS: `${BASE_URL}/rpt-sny-details`,
  RPT_AIRPORT_CREW_ROSTER_DETAILS: `${BASE_URL}/rpt-airport-crew-roster-details`,
  CREW_CATEGORIES_WITH_PAYMENT_RATES: `${BASE_URL}/crew-categories-with-payment-rates`,
  UPDATE_PAYMENT_RATES: `${BASE_URL}/update-payment-rates`,

  CREATE_OR_UPDATE_PAYMENT_SECTOR: `${BASE_URL}/create-or-update-payment-sector`,
  PAYMENT_SECTORS: `${BASE_URL}/payment-sectors`,
  DELETE_PAYMENT_SECTOR: `${BASE_URL}/delete-payment-sector`,
  MIGRATE_PAYMENT_SECTORS: `${BASE_URL}/migrate-payment-sectors`,


  GET_CREW_WATCH_DATA: `${BASE_URL}/getcrew-watch-data`,
  DELETE_PAIRING_FRM_CREWING_ENTRY : `${BASE_URL}/delete-pairing-from-crewing-entry`,
  CREW_DATA_AND_TRAININGS: `${BASE_URL}/crewData-and-trainings`,
  TRAINING_EVENT: `${BASE_URL}/training-event`,
  TRAINING_EVENT_DETAILS: `${BASE_URL}/training-event-details`,
  CREATE_TRAINING_EVENT: `${BASE_URL}/create-training-event`,
  UPDATE_TRAINING_EVENT: `${BASE_URL}/update-training-event`,
  DELETE_TRAINING_EVENT: `${BASE_URL}/delete-training-event`,
  GET_PAIRING_FLIGHTSET_EDIT: `${BASE_URL}/get-pairing-flightset-edit`,
  RPT_LEAVE_AND_DUTY_ANALYSIS_DATA: `${BASE_URL}/leave-and-duty-analysis-data`,

  CREATE_AND_UPDATE_DAY_NOTE:`${BASE_URL}/create-and-update-day-note`,
  DELETE_DAY_NOTE:`${BASE_URL}/delete-day-note`,

  GENERATE_CABIN_CREW_TRANSPORT_PAYMENT_REPORT:`${BASE_URL}/generate-cabin-crew-transport-payment-report`,

  FDA_RULES:`${BASE_URL}/get-fda-rules`,
  SAVE_FDA_RULE:`${BASE_URL}/save-fda-rule`,
  UPDATE_FDA_RULE:`${BASE_URL}/update-fda-rule`,
  DELETE_FDA_RULE:`${BASE_URL}/delete-fda-rule`,

  GENERATE_NOTE_BASED_ROSTER_REPORT:`${BASE_URL}/note-based-roster`,
  
  BASE_URL: BASE_URL 
}
